<script lang="ts" setup>
import type { SwiperOptions } from 'swiper'

import type { Banner as BannerType } from '~/types/banners'

const props = defineProps<{
  banners: BannerType[]
}>()

const speedInMs = 300
const galleryOptions: SwiperOptions = {
  autoplay: { delay: 5 * 1000, disableOnInteraction: false, pauseOnMouseEnter: true },
  breakpoints: {
    768: { slidesPerGroup: 1, slidesPerView: 1 },
    0: { slidesPerGroup: 1, slidesPerView: 1.15, spaceBetween: 8 }
  },
  lazy: true,
  spaceBetween: 24,
  speed: speedInMs,
  on: {
    transitionEnd (swiper) {
      const { activeIndex, previousIndex, slides } = swiper
      const slidesCount = slides.length
      swiper.params.speed = slidesCount > 2 && activeIndex === slidesCount - 1 && previousIndex === slidesCount - 2
        ? speedInMs * (slidesCount - 1)
        : speedInMs
    }
  }
}

const isGallery = computed(() => props.banners.length > 1)
</script>

<template>
  <section v-if="banners.length">
    <Gallery :is-gallery="isGallery" :options="galleryOptions">
      <div
        v-for="x in banners"
        :key="x.title"
        class="swiper-slide"
        :class="{ 'max-1-slide': isGallery }"
      >
        <Banner :banner="x" />
      </div>
    </Gallery>
  </section>
</template>

<style lang="scss" scoped>
@use 'assets/css/gallery';
</style>
