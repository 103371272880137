<script lang="ts" setup>
import { useModal } from 'vue-final-modal'

import BasicButton from '~/components/form-elements/BasicButton.vue'
import type { Banner } from '~/types/banners'

const props = defineProps<{
  banner: Banner
}>()

const router = useRouter()

async function performAction (isButton: boolean): Promise<void> {
  const { modalWindow, modalWindowForButton, path } = props.banner

  if (isButton && modalWindowForButton) {
    await useModal({ component: modalWindowForButton }).open()

    return
  }

  if (path) {
    if (path.startsWith('http')) {
      window.open(path, '_blank')
    } else {
      await router.push(path)
    }

    return
  }

  if (modalWindow) {
    await useModal({ component: modalWindow }).open()

    return
  }

  console.error('No "path", "modalWindow" or "modalWindowForButton".')
}
</script>

<template>
  <div class="banner-area flex" :style="{ backgroundColor: banner.backgroundColor }">
    <div class="banner-content flex justify-between">
      <div class="banner-content__header" :style="{ color: banner.color }">
        <div class="banner-content__title h1 mb-4">
          <a class="text-inherit" href="#" @click.prevent="performAction(false)">
            {{ banner.title }}
          </a>
        </div>

        <pre v-if="banner.description" class="banner-content__description pre-wrap">{{ banner.description }}</pre>

        <div v-if="banner.smallDescription" class="banner-content__small-description banner-content__small-description_desktop mt-4">
          {{ banner.smallDescription }}
        </div>
      </div>

      <div class="banner-content__footer">
        <img
          v-lazy="banner.picture.src2"
          :alt="banner.title"
          fetchpriority="high"
          loading="lazy"
          @click="performAction(false)"
        >

        <span
          v-if="banner.smallDescription"
          class="banner-content__small-description banner-content__small-description_mobile text-white"
        >
          {{ banner.smallDescription }}
        </span>

        <BasicButton
          class="banner-button"
          :color="banner.buttonColor"
          is-small
          :title="banner.buttonTitle"
          @click="performAction(true)"
        />
      </div>
    </div>

    <div class="banner-picture cursor-pointer" @click="performAction(false)">
      <img
        v-lazy="banner.picture.src"
        :alt="banner.title"
        fetchpriority="high"
        loading="lazy"
      >
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use 'assets/css/variables';

$maxWidth: 768px;

.banner-area {
  border-radius: 12px;
  height: 370px;
  overflow: hidden;

  @media screen and (max-width: $maxWidth) {
    height: 100%;
  }
}

.banner-content {
  box-sizing: border-box;
  flex-flow: column;
  min-width: 368px;
  position: relative;
  width: 368px;

  @media screen and (max-width: $maxWidth) {
    min-width: auto;
    width: 100%;
  }

  &__description {
    @media screen and (max-width: $maxWidth) {
      font-size: 14px;
    }
  }

  &__description,
  &__small-description {
    line-height: 20px;
  }

  &__footer {
    height: 120px;
    position: relative;

    @media screen and (min-width: $maxWidth) {
      img {
        display: none !important;
      }
    }
  }

  &__header {
    padding: 24px;
  }

  &__title {
    font-weight: 500;
    line-height: 1em;

    @media screen and (max-width: $maxWidth) {
      font-size: 16px;
    }
  }

  &__small-description {
    &_desktop {
      font-size: 14px;

      @media screen and (max-width: $maxWidth) {
        display: none;
      }
    }

    &_mobile {
      background: linear-gradient(180deg, rgba(black, 60%) 0%, transparent 100%);
      min-height: 50px;
      padding: 8px 16px;
      display: none;
      font-size: 12px;
      line-height: 20px;

      @media screen and (max-width: $maxWidth) {
        display: block;
      }
    }
  }
}

.banner-button {
  bottom: 24px;
  left: 24px;
  position: absolute;

  @media screen and (max-width: $maxWidth) {
    bottom: 16px;
    left: 16px;
  }
}

.banner-picture {
  max-width: 832px; // 832px = 1200px - 368px.
  width: 100%;

  @media screen and (max-width: $maxWidth) {
    display: none;
  }
}

.banner-content,
.banner-picture {
  img {
    height: 100%;
    object-fit: cover;
    width: 100%;
  }
}
</style>
