enum DealerContactPersonPosition {
  BusinessOwner = '1',
  PurchasingManager = '2'
}

const { BusinessOwner, PurchasingManager } = DealerContactPersonPosition

const nameMapping = new Map([
  [BusinessOwner, 'Собственник бизнеса'],
  [PurchasingManager, 'Менеджер по закупкам']
])

namespace DealerContactPersonPosition {
  export const sortedMembers = [BusinessOwner, PurchasingManager]

  export function getName (dealerContactPersonPosition: DealerContactPersonPosition): string {
    return nameMapping.get(dealerContactPersonPosition)!
  }
}

export default DealerContactPersonPosition
